import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cx from 'classnames'

import styles from './CategoryCard.module.scss'

const Card = ({ data }) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <article className={styles.categoryCard} >
      <Link className={styles.thumbnail} to={`/categories/${data.slug}/`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} aria-label={data.name}>
        <Img fluid={{...data.listedImage.childImageSharp.fluid, aspectRatio: 180/230 }} alt={data.listedImageAlt} className={styles.thumbnailImg} />
      </Link>

      <Link to={`/categories/${data.slug}/`} className={cx(styles.name, { [styles.nameActive]: isHovering })}>
        <h1>{data.name}</h1>
      </Link>
      <div className={styles.count}>({data.count || 0} video{data.count !== 1 ? 's' : ''})</div>
    </article>
  )
}

export default Card