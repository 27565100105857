import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import SEO from 'components/SEO'
import Select from 'components/Select'
import CategoryCard from 'components/CategoryCard'
import PartnersLinks from 'components/PartnersLinks'

import styles from './CategoryList.module.scss'

const PopularCategoriesPage = ({ data, pageContext }) => {
  const { categories, site: { siteMetadata }, banner} = data
  const { categoryCounts } = pageContext

  categories.nodes.sort((catA, catB) => (categoryCounts[catB.name] || 0) - (categoryCounts[catA.name] || 0))

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/categories/`,
          "name": "Categories"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/categories/popular/`,
          "name": "Most popular"
        }
      },
    ]
  }

  const sloppyPartners = []
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`SloppyDeep Categories: Find your favorite cam porn on the best webcam sex tube`}
      description={`Watch the best teen webcam porn and discover what cam girls are doing in their private shows. Just choose your category, explore our cam models and play your favorite free porn video. Only from Sloppydeep.com`}
      keywords={`most popular camgirl, cam girl videos, best cam tube, cam porn category`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/categories/popular/`}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <main className={`${styles.categoryListScreen} screen-container`}>
      <div className={styles.titleBlock}>
        <div className={styles.sectionTitle}>Categories</div>
        <div className={styles.sectionFilter}>
          <Select selected={1} options={[{ text: 'A to Z', to: '/categories/' }, { text: 'Popular', to: '/categories/popular/' }]} />
        </div>
      </div>
      <div className={`${styles.categoriesBlock} category-cards-container`}>
        {categories.nodes.map((category, idx) => <CategoryCard key={idx} data={{ ...category, count: categoryCounts[category.name] }} />)}
      </div>

      {sloppyPartners.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={sloppyPartners} />
          </div>
        </>
      }

    </main>
    </>
  )
}

export const query = graphql`
  query PopularCategoriesPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    categories: allSloppyDeepCategory {
      nodes {
        slug
        name
        listedImageAlt
        listedImage {
          childImageSharp {
            fluid(maxWidth: 353, maxHeight: 450, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default PopularCategoriesPage
